<template>
  <page-view>
    <div class="normal_header">
      <a-row :gutter="48">
        <a-col :lg="6" :md="12" :sm="24">
          <div class="filter_item">
            <span>T单号</span>
            <a-input
              placeholder="T单号"
              v-model.trim="queryParam.no"
              class="filter_input"
            />
          </div>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <div class="filter_item">
            <span>订单号</span>
            <a-input
              placeholder="订单号"
              v-model.trim="queryParam.orderNo"
              class="filter_input"
            />
          </div>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <div class="filter_item">
            <span>订单ID</span>
            <a-input
              placeholder="订单ID"
              v-model.trim="queryParam.id"
              class="filter_input"
            />
          </div>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <div class="filter_item">
            <span>用户ID</span>
            <a-input
              placeholder="用户ID"
              v-model.trim="queryParam.uid"
              class="filter_input"
            />
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="48">
        <a-col :lg="6" :md="12" :sm="24">
          <div class="filter_item">
            <span>贴牌ID</span>
            <a-input
              placeholder="贴牌ID"
              v-model.trim="queryParam.oemUid"
              class="filter_input"
            />
          </div>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <div class="filter_item">
            <span>快递编号</span>
            <a-input
              placeholder="快递编号"
              v-model.trim="queryParam.waybillNo"
              class="filter_input"
            />
          </div>
        </a-col>

        <a-col :lg="6" :md="12" :sm="24">
          <div class="filter_item">
            <span>查询时间</span>
            <a-range-picker
              class="filter_input"
              :value="[
                moment(queryParam.createTimeStart),
                moment(queryParam.createTimeEnd)
              ]"
              @change="onChangeDatePay"
            />
          </div>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <div class="time_select">
            <span
              v-for="item in shortcutDate"
              :key="item.id"
              @click="handleShortcutDate(item)"
              :class="currentShortcutDate === item.date ? 'active' : ''"
              >{{ item.title }}</span
            >
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="48">
        <a-col :lg="6" :md="12" :sm="24">
          <div class="filter_item">
            <span>手机号</span>
            <a-input
              placeholder="手机号"
              v-model.trim="queryParam.mobile"
              class="filter_input"
            />
          </div>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <div class="filter_item">
            <a-button
              type="primary"
              class="query_btn"
              @click="$refs.table.refresh(false)"
              >查询</a-button
            >
            <a-button class="query_btn" @click="resetQueryParam">重置</a-button>
            <a-button :loading="exportLoading" @click="exportAll">
              一键导出
            </a-button>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-card :bordered="false">
      <s-table
        style="margin-top: 10px"
        ref="table"
        rowKey="id"
        size="small"
        :columns="columns"
        :data="dataSource"
      >
        <span slot="serial" slot-scope="text, record, index">{{
          index + 1
        }}</span>
        <template slot="no" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span
              class="uid_fat"
              @click="copyInfo(record.no)"
              @mouseover="handleMouseover(record, 'show6')"
              @mouseout="handleMouseout(record, 'show6')"
            >
              {{
                text.substring(0, 2) +
                  "……" +
                  text.substring(text.split("").length - 2)
              }}

              <span class="uid_child" v-show="record.show6">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>
        <template slot="mobile" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span
              class="uid_fat"
              @click="copyInfo(record.mobile)"
              @mouseover="handleMouseover(record, 'show7')"
              @mouseout="handleMouseout(record, 'show7')"
            >
              <span v-if="text.length > 15">
                {{
                  text.substring(0, 2) +
                    "……" +
                    text.substring(text.split("").length - 2)
                }}
              </span>
              <span v-else>
                {{ text }}
              </span>
              <span class="uid_child" v-show="record.show7">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>
        <template slot="shopName" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span
              class="uid_fat"
              @click="copyInfo(record.shopName)"
              @mouseover="handleMouseover(record, 'show9')"
              @mouseout="handleMouseout(record, 'show9')"
            >
              <span v-if="text.length > 5">
                {{
                  text.substring(0, 2) +
                    "……" +
                    text.substring(text.split("").length - 2)
                }}
              </span>
              <span v-else>
                {{ text }}
              </span>
              <span class="uid_child" v-show="record.show9">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>

        <template slot="name" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span
              class="uid_fat"
              @click="copyInfo(record.name)"
              @mouseover="handleMouseover(record, 'show8')"
              @mouseout="handleMouseout(record, 'show8')"
            >
              <span v-if="text.length > 8">
                {{
                  text.substring(0, 2) +
                    "……" +
                    text.substring(text.split("").length - 2)
                }}
              </span>
              <span v-else>
                {{ text }}
              </span>
              <span class="uid_child" v-show="record.show8">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>
        <template slot="orderNo" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span
              class="uid_fat"
              @click="copyInfo(record.orderNo)"
              @mouseover="handleMouseover(record, 'show1')"
              @mouseout="handleMouseout(record, 'show1')"
            >
              {{
                text.substring(0, 2) +
                  "……" +
                  text.substring(text.split("").length - 2)
              }}
              <span class="uid_child" v-show="record.show1">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>

        <template slot="inviterUid" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span
              class="uid_fat"
              @click="copyInfo(record.inviterUid)"
              @mouseover="handleMouseover(record, 'show2')"
              @mouseout="handleMouseout(record, 'show2')"
            >
              {{
                text.substring(0, 2) +
                  "……" +
                  text.substring(text.split("").length - 2)
              }}
              <span class="uid_child" v-show="record.show2">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>
        <template slot="uid" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span
              class="uid_fat"
              @click="copyInfo(record.uid)"
              @mouseover="handleMouseover(record, 'show3')"
              @mouseout="handleMouseout(record, 'show3')"
            >
              {{
                text.substring(0, 2) +
                  "……" +
                  text.substring(text.split("").length - 2)
              }}
              <span class="uid_child" v-show="record.show3">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>
        <template slot="oemUid" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span
              class="uid_fat"
              @click="copyInfo(record.oemUid)"
              @mouseover="handleMouseover(record, 'show4')"
              @mouseout="handleMouseout(record, 'show4')"
            >
              {{
                text.substring(0, 2) +
                  "……" +
                  text.substring(text.split("").length - 2)
              }}
              <span class="uid_child" v-show="record.show4">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>
        <template slot="addressStr" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span
              class="uid_fat"
              @click="copyInfo(record.addressStr)"
              @mouseover="handleMouseover(record, 'show5')"
              @mouseout="handleMouseout(record, 'show5')"
            >
              {{
                text.substring(0, 2) +
                  "……" +
                  text.substring(text.split("").length - 2)
              }}
              <span class="uid_child" v-show="record.show5">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>

        <template slot="comment" slot-scope="text, record">
          <span
            class="uid_fat"
            @mouseover="handleMouseover(record, 'show10')"
            @mouseout="handleMouseout(record, 'show10')"
          >
            {{
              text.substring(0, 2) +
                "……" +
                text.substring(text.split("").length - 2)
            }}
            <span class="uid_child" v-show="record['show10']">{{ text }}</span>
          </span>
        </template>
        <template slot="sellUid" slot-scope="text">
          {{ addressObj[text] ? addressObj[text] : "已下架" }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a-popconfirm
            title="是否关闭?（不能恢复）"
            @confirm="() => closeDetail(record.id)"
          >
            <a>关闭</a>
          </a-popconfirm>
        </template>
      </s-table>
    </a-card>
  </page-view>
</template>
<script>
import { STable } from "@/components";
import { PageView } from "@/layouts";
import {
  orderDetailList,
  addressList,
  goodsListAll,
  orderDetailClose
} from "@api";
import { formatDate, platformType } from "@/utils/util";
import { renderStatus } from "@/utils/app";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
import { exportXLSX } from "@/utils/excel/export";
export default {
  data() {
    return {
      addressObj: {},
      goodObj: {},
      pasteText: "",
      exportLoading: false,
      order: { show: false, content: {}, record: {} },

      preview: { image: "", show: false },
      solve: { show: false },
      queryParam: {
        createTimeStart: new Date(new Date().toLocaleDateString()).getTime(),
        createTimeEnd:
          new Date(new Date().toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000
      },
      currentShortcutDate: 0,
      shortcutDate: [
        {
          id: 1,
          title: "今天",
          date: 0
        },
        {
          id: 2,
          title: "昨天",
          date: 1
        },
        {
          id: 3,
          title: "最近7天",
          date: 7
        },
        {
          id: 4,
          title: "最近30天",
          date: 30
        }
      ],
      columns: [
        { title: "#", scopedSlots: { customRender: "serial" }, width: 50 },
        { title: "店铺id", dataIndex: "shopId" },
        {
          title: "店铺名",
          dataIndex: "shopName",
          scopedSlots: { customRender: "shopName" }
        },
        {
          title: "T单号",
          dataIndex: "no",
          scopedSlots: { customRender: "no" }
        },

        { title: "订单id", dataIndex: "id" },
        {
          title: "订单号",
          dataIndex: "orderNo",
          scopedSlots: { customRender: "orderNo" }
        },
        {
          title: "商品",
          dataIndex: "goodsId",
          customRender: text => this.goodObj[text]
        },
        { title: "数量", dataIndex: "count" },
        {
          title: "状态",
          dataIndex: "status",
          customRender: renderStatus
        },
        {
          title: "姓名",
          dataIndex: "name",
          scopedSlots: { customRender: "name" }
        },
        {
          title: "手机号",
          dataIndex: "mobile",
          scopedSlots: { customRender: "mobile" }
        },
        { title: "快递公司", dataIndex: "waybillCompany" },
        { title: "快递编号", dataIndex: "waybillNo" },
        {
          title: "收货地址",
          dataIndex: "addressStr",
          scopedSlots: { customRender: "addressStr" }
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          customRender: formatDate
        },

        {
          title: "付款时间",
          dataIndex: "payTime",
          customRender: formatDate
        },
        {
          title: "发货时间",
          dataIndex: "deliveryTime",
          customRender: formatDate
        },
        {
          title: "平台",
          dataIndex: "platformType",
          customRender: text => platformType[text]
        },
        {
          title: "代发端",
          dataIndex: "sellUid",
          // customRender: (text) => this.addressObj[text],
          scopedSlots: { customRender: "sellUid" }
        },
        {
          title: "备注",
          dataIndex: "comment",
          scopedSlots: { customRender: "comment" }
        },
        {
          title: "推广人ID",
          dataIndex: "inviterUid",
          scopedSlots: { customRender: "inviterUid" }
        },
        {
          title: "贴牌ID",
          dataIndex: "oemUid",
          scopedSlots: { customRender: "oemUid" }
        },
        {
          title: "用户ID",
          dataIndex: "uid",
          scopedSlots: { customRender: "uid" }
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" }
        }
      ],
      dataSource: parameter => {
        return orderDetailList(
          Object.assign(parameter, this.queryParam),
          0
        ).then(result => {
          result.data.list &&
            result.data.list.forEach(item => {
              item.show1 = false;
              item.show2 = false;
              item.show3 = false;
              item.show4 = false;
              item.show5 = false;
              item.show6 = false;
              item.show7 = false;
              item.show8 = false;
              item.show9 = false;
              item.show10 = false;
              item.addressStr =
                item.province + item.city + item.county + item.address;
            });
          return result.data;
        });
      }
    };
  },
  created() {
    // 仓库
    addressList("").then(res => {
      const obj = {};
      res.data.forEach(item => {
        // const uid = item.uid
        // const site = item.province + item.city
        // obj[uid] = site
        obj[item.uid] = item.name;
      });
      this.addressObj = obj;
    });
    goodsListAll({ pageNum: 1, pageSize: 1000 }).then(res => {
      const obj = {};
      res.data &&
        res.data.list.forEach(item => {
          const id = item.id;
          obj[id] = item.name;
        });
      this.goodObj = obj;
    });
  },
  components: {
    PageView,
    STable
  },
  methods: {
    moment,
    async exportAll() {
      const pageSize = 1000;
      const param = JSON.parse(JSON.stringify(this.queryParam));
      const p = { ...param, pageSize, pageNum: 1 };
      this.exportLoading = true;
      const res = await orderDetailList(p, 0);
      if (!res.success) {
        this.exportLoading = false;
        return;
      }
      const result = [];
      const { total } = res.data.page;
      const count = Math.ceil(total / pageSize);
      result.push(...res.data.list);
      for (let pageNum = 2; pageNum <= count; pageNum++) {
        const response = await orderDetailList({ ...p, pageNum }, 0);
        if (response.success) {
          result.push(...response.data.list);
        }
      }
      if (!result.length) {
        this.exportLoading = false;
        return this.$message.info("无数据导出");
      }
      const exportArr = [
        this.columns.map(val => val.title).filter(title => title !== "操作")
      ];
      const contentArr = result.map(item => {
        return this.columns
          .filter(val => val.title !== "操作")
          .map((val, index) => {
            if (val.title === "#") return index + 1;
            if (val.dataIndex === "status") {
              return renderStatus(item.status);
            } else if (val.dataIndex === "platformType") {
              return platformType[item.platformType];
            } else if (val.dataIndex === "goodsId") {
              return this.goodObj[item.goodsId];
            } else if (
              ["createTime", "payTime", "deliveryTime"].includes(val.dataIndex)
            ) {
              return formatDate(item[val.dataIndex]);
            }
            if (val.dataIndex === "sellUid")
              return this.addressObj[item.sellUid];
            return item[val.dataIndex];
          });
      });
      exportArr.push(...contentArr);
      exportXLSX(exportArr, `订单导出${new Date().getTime()}`);
      this.exportLoading = false;
    },
    onChangeDatePay(date, dateString) {
      const time = dateString.includes("");
      if (time) {
        delete this.queryParam.createTimeStart;
        delete this.queryParam.createTimeEnd;
      } else {
        this.queryParam.createTimeStart =
          Date.parse(new Date(dateString[0])) - 1000 * 3600 * 8;
        this.queryParam.createTimeEnd =
          Date.parse(new Date(dateString[1])) - 1000 * 3600 * 8;
      }
      this.currentShortcutDate = null;
      this.$refs.table.refresh(false);
    },
    closeDetail(id) {
      orderDetailClose("sell", id).then(res => {
        if (res.success) {
          this.$message.success("关闭成功");
          this.$refs.table.refresh(false);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleShortcutDate(record) {
      this.currentShortcutDate = record.date;

      let createTimeStart;
      let createTimeEnd;
      const today = new Date(new Date().toLocaleDateString()).getTime();
      // 今天
      if (record.date === 0) {
        createTimeStart = today;
        createTimeEnd = today + 24 * 60 * 60 * 1000;
      }
      // 7天内  30天内
      if (record.date === 7 || record.date === 30) {
        createTimeEnd = today + 24 * 60 * 60 * 1000;
        createTimeStart = today - 24 * 60 * 60 * 1000 * (record.date - 1);
      }
      // 昨天
      if (record.date === 1) {
        createTimeStart = today - 24 * 60 * 60 * 1000;
        createTimeEnd = today;
      }
      this.queryParam.createTimeStart = createTimeStart;
      this.queryParam.createTimeEnd = createTimeEnd;
      this.$refs.table.refresh(false);
    },
    resetQueryParam() {
      this.currentShortcutDate = 0;
      this.queryParam = {
        createTimeStart: new Date(new Date().toLocaleDateString()).getTime(),
        createTimeEnd:
          new Date(new Date().toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000
      };
      this.$refs.table.refresh(false);
    },

    handleMouseover(record, key) {
      record[key] = true;
    },
    handleMouseout(record, key) {
      record[key] = false;
    }
  }
};
</script>

<style lang="less" scoped>
.normal_header {
  padding: 24px 80px 0;
  margin: -24px -24px 24px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
}
.filter_item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  span {
    width: 56px;
    text-align: right;
  }
  .filter_input {
    width: 240px;
    margin-left: 6px;
  }
  &:nth-child(2) {
    margin-right: 0;
  }
}
.time_select {
  span {
    display: inline-block;
    margin-right: 24px;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
  }
  .active {
    color: #1890ff;
  }
}
.query_btn {
  margin-right: 12px;
}
.top5 {
  margin-bottom: 8px;
}

.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -50px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    z-index: 9999;
  }
}
</style>
